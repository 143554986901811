/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
@import '../../../node_modules/primeng/resources/themes/nova/theme.css';
@import '../../../node_modules/primeng/resources/primeng.min.css';

// Common Css

/* You can add global styles to this file, and also import other style files */
// Start common css
html,
body {
  word-wrap: break-word;
  outline: none;
  margin: 0;
  padding: 0;
  height: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

textarea {
  overflow: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
dd,
dt,
dl {
  -webkit-margin-before: 0px;
  -webkit-margin-after: 0px;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
}

a img,
img {
  border: none;
  outline: none;
}
img {
  width: auto;
  max-width: 100%;
  display: block;
}

input[type='submit'],
input[type='reset'],
input[type='button'],
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
}

a,
button {
  cursor: pointer;
  text-decoration: none;
  outline: 0;
  color: inherit;
  transition: 0.3s;
  &:hover {
    text-decoration: none;
  }
}

input {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  outline: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-font-smoothing: antialiased;
  background-color: #fff;
  // overflow-x: hidden;
}

::selection {
  text-shadow: none;
  background: rgba(65, 131, 196, 0.4);
}

.clear {
  clear: both;
  height: 0px;
  overflow: hidden;
  width: auto;
  display: block;
  float: none !important;
}

ul,
menu,
dir {
  margin: 0px;
  padding: 0px;
  display: block;
  list-style-type: none;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  -webkit-padding-start: 0;
}

*,
*:after,
*:before {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: 0;
}

@font-face {
  font-family: 'Sharp Sans';
  src:
    url('/assets/fonts/SharpSans-BoldItalic.woff2') format('woff2'),
    url('/assets/fonts/SharpSans-BoldItalic.woff') format('woff'),
    url('/assets/fonts/SharpSans-BoldItalic.ttf') format('truetype'),
    url('/assets/fonts/SharpSans-BoldItalic.svg#SharpSans-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Sharp Sans';
  src:
    url('/assets/fonts/SharpSans-ExtraboldItalic.woff2') format('woff2'),
    url('/assets/fonts/SharpSans-ExtraboldItalic.woff') format('woff'),
    url('/assets/fonts/SharpSans-ExtraboldItalic.ttf') format('truetype'),
    url('/assets/fonts/SharpSans-ExtraboldItalic.svg#SharpSans-ExtraboldItalic') format('svg');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Sharp Sans';
  src:
    url('/assets/fonts/SharpSans-Bold.woff2') format('woff2'),
    url('/assets/fonts/SharpSans-Bold.woff') format('woff'),
    url('/assets/fonts/SharpSans-Bold.ttf') format('truetype'),
    url('/assets/fonts/SharpSans-Bold.svg#SharpSans-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Sharp Sans';
  src:
    url('/assets/fonts/SharpSans-Extrabold.woff2') format('woff2'),
    url('/assets/fonts/SharpSans-Extrabold.woff') format('woff'),
    url('/assets/fonts/SharpSans-Extrabold.ttf') format('truetype'),
    url('/assets/fonts/SharpSans-Extrabold.svg#SharpSans-Extrabold') format('svg');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Sharp Sans';
  src:
    url('/assets/fonts/SharpSans-BookItalic.woff2') format('woff2'),
    url('/assets/fonts/SharpSans-BookItalic.woff') format('woff'),
    url('/assets/fonts/SharpSans-BookItalic.ttf') format('truetype'),
    url('/assets/fonts/SharpSans-BookItalic.svg#SharpSans-BookItalic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Sharp Sans';
  src:
    url('/assets/fonts/SharpSans-LightItalic.woff2') format('woff2'),
    url('/assets/fonts/SharpSans-LightItalic.woff') format('woff'),
    url('/assets/fonts/SharpSans-LightItalic.ttf') format('truetype'),
    url('/assets/fonts/SharpSans-LightItalic.svg#SharpSans-LightItalic') format('svg');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Sharp Sans';
  src:
    url('/assets/fonts/SharpSans-Light.woff2') format('woff2'),
    url('/assets/fonts/SharpSans-Light.woff') format('woff'),
    url('/assets/fonts/SharpSans-Light.ttf') format('truetype'),
    url('/assets/fonts/SharpSans-Light.svg#SharpSans-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Sharp Sans';
  src:
    url('/assets/fonts/SharpSans-MediumItalic.woff2') format('woff2'),
    url('/assets/fonts/SharpSans-MediumItalic.woff') format('woff'),
    url('/assets/fonts/SharpSans-MediumItalic.ttf') format('truetype'),
    url('/assets/fonts/SharpSans-MediumItalic.svg#SharpSans-MediumItalic') format('svg');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Sharp Sans';
  src:
    url('/assets/fonts/SharpSans-Medium.woff2') format('woff2'),
    url('/assets/fonts/SharpSans-Medium.woff') format('woff'),
    url('/assets/fonts/SharpSans-Medium.ttf') format('truetype'),
    url('/assets/fonts/SharpSans-Medium.svg#SharpSans-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Sharp Sans';
  src:
    url('/assets/fonts/SharpSans-SemiboldItalic.woff2') format('woff2'),
    url('/assets/fonts/SharpSans-SemiboldItalic.woff') format('woff'),
    url('/assets/fonts/SharpSans-SemiboldItalic.ttf') format('truetype'),
    url('/assets/fonts/SharpSans-SemiboldItalic.svg#SharpSans-SemiboldItalic') format('svg');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Sharp Sans';
  src:
    url('/assets/fonts/SharpSans-SemiBold.woff2') format('woff2'),
    url('/assets/fonts/SharpSans-SemiBold.woff') format('woff'),
    url('/assets/fonts/SharpSans-SemiBold.ttf') format('truetype'),
    url('/assets/fonts/SharpSans-SemiBold.svg#SharpSans-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Sharp Sans';
  src:
    url('/assets/fonts/SharpSans-ThinItalic.woff2') format('woff2'),
    url('/assets/fonts/SharpSans-ThinItalic.woff') format('woff'),
    url('/assets/fonts/SharpSans-ThinItalic.ttf') format('truetype'),
    url('/assets/fonts/SharpSans-ThinItalic.svg#SharpSans-ThinItalic') format('svg');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Sharp Sans';
  src:
    url('/assets/fonts/SharpSans-Thin.woff2') format('woff2'),
    url('/assets/fonts/SharpSans-Thin.woff') format('woff'),
    url('/assets/fonts/SharpSans-Thin.ttf') format('truetype'),
    url('/assets/fonts/SharpSans-Thin.svg#SharpSans-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Sharp Sans';
  src:
    url('/assets/fonts/SharpSans-Book.woff2') format('woff2'),
    url('/assets/fonts/SharpSans-Book.woff') format('woff'),
    url('/assets/fonts/SharpSans-Book.ttf') format('truetype'),
    url('/assets/fonts/SharpSans-Book.svg#SharpSans-Book') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  font-weight: normal;
  * {
    font-family: 'Sharp Sans';
  }
}

h1 {
  font-size: 30px;
  line-height: 40px;
  font-weight: 700;
  color: #000;
  display: block;
}
.action-btn {
  .p-button {
    font-size: 16px;
    line-height: 21px;
    font-weight: 700;
    color: #fff;
    display: flex;
    padding: 14px 18px;
    background: #f7923e;
    border-radius: 10px;
    border: none;
    &:enabled:hover {
      background: #f38327;
      color: #ffffff;
      border-color: transparent;
    }
  }
}

// form css
.main-form-part {
  .action-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .form-input-block {
      display: flex;
      flex-direction: column;
      .form-label {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        color: #000;
        display: block;
        margin-bottom: 10px;
      }
      .form-input {
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
        color: #aaa;
        display: block;
        padding: 14px 18px;
        box-shadow: none;
        background: #f6f6f6;
        border: 1px solid #f6f6f6;
        border-radius: 10px;
      }
      .form-input.p-inputtext.ng-dirty.ng-invalid {
        border-color: #a80000 !important;
      }
    }
    .action-btn-part {
      margin-top: 10px;
      .login-btn {
        .p-button {
          width: 190px;
          justify-content: space-between;
          .p-button-label {
            text-align: left;
          }
        }
      }
    }
  }
}
// End form css

.login-sign-part {
  max-width: 850px;
  width: 100%;
  margin: 0px auto;
  .login-sign-inner {
    padding: 100px 125px;
    .dmc-logo {
      img {
        width: auto;
        height: auto;
        max-width: 100%;
        display: block;
      }
    }
    h1 {
      margin: 70px 0 40px;
    }
  }
}

@media screen and (max-width: 870px) {
  h1 {
    font-size: 26px;
    line-height: 36px;
  }
  .login-sign-part {
    .login-sign-inner {
      padding: 50px 80px 30px;
      .dmc-logo {
        img {
          max-width: 220px;
        }
      }
      h1 {
        margin: 40px 0 20px;
      }
    }
  }
}

@media screen and (max-width: 599px) {
  h1 {
    font-size: 24px;
    line-height: 34px;
  }
  .login-sign-part {
    .login-sign-inner {
      padding: 30px 25px 20px;
      .dmc-logo {
        img {
          max-width: 200px;
        }
      }
      h1 {
        margin: 30px 0 20px;
      }
    }
  }
}

// primeng overwrite css
.p-dialog-mask {
  align-items: flex-start !important;
  .p-dialog {
    top: 125px;
  }
}
.p-dialog {
  gap: 20px;
  border: 0;
  display: flex;
  color: #333;
  padding: 30px;
  border-radius: 0;
  box-shadow: none;
  padding-bottom: 35px;
  width: min(90%, 800px);
  flex-direction: column;
  background-color: #fff;
  & &-header,
  & &-content,
  & &-footer {
    border: 0;
    padding: 0;
    color: inherit;
    background-color: transparent;
  }
  & &-header .p-dialog-title {
    font-size: 24px;
  }
  & &-content {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    .p-confirm-dialog-icon {
      margin-right: 1rem;
    }
    .p-confirm-dialog-message {
      margin: 0;
    }
  }
  & &-footer {
    .p-button {
      margin: 0;
      padding: 15px 35px 10px;
      font-size: 16px;
      font-weight: bold;
      box-shadow: none !important;
    }
    .p-confirm-dialog-accept {
      color: #fff;
      border-radius: 10px;
      background-color: #f7923e;
      border: 2px solid #f7923e;
      &:hover {
        color: #f7923e;
        background-color: #fff;
      }
    }
    .p-confirm-dialog-reject {
      color: #777;
      border-radius: 10px;
      padding-left: 10px;
      padding-right: 10px;
      margin-right: 20px;
      background-color: transparent;
      border: 2px solid transparent;
    }
  }
}
